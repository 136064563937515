import Footer, { ButtonWrapper, FooterWrapper } from './Footer';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import StepItemStep, { UnderMOQ } from './StepItem';
import StepRowStep, { StepBox as StepBoxStep } from './StepRow';
import Value, { Description, SupplierName } from './Value';
import media from 'utils/mediaStyle';
import Button from '../Button/Button';
import CartTotal from './CartTotal';
import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
import Label from './Label';
import { Modal } from 'antd';
import Money from '../Money/Money';
import OrderTable from './OrderTable';
import React, { FC, useMemo } from 'react';
import StepColStep from './StepCol';
import StepContainer from './StepContainer';
import SubplierMark from '../SubplierMark/SubplierMark';
import { Viewport } from 'components/ViewportProvider';
import messages from 'containers/CheckoutPage/messages';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import { Cart, CartItem, SingleSupplierCart } from 'types/schema';
import { ICheckout } from 'containers/CheckoutPage/types';
import LazyImage from 'components/Image/LazyImage';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import warningIcon from './warning_icon.svg';
import noImage from './noIMG.svg';
import generateProductSlug from 'utils/generateProductSlug';
import translations from 'translations';
import { Link } from 'react-router-dom';

const StepItem = styled(StepItemStep)`
  @media (max-width: 575px) {
    display: block;
  }
`;
const StepCol = styled(StepColStep)`
  @media (max-width: 575px) {
    display: flex;
    padding: 5px 0;
    justify-content: space-between;
  }
`;
const StepBox = styled(StepBoxStep)`
  @media (max-width: 575px) {
    display: block;
    padding: 10px;
  }
`;
const StepRow = styled(StepRowStep)`
  @media (max-width: 575px) {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ExclusiveContainer = styled.div`
  max-width: 760pt;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border: 1px solid #f5ce47;
  border-radius: 8px;
  margin-bottom: 20px;
  background: #fff;

  ${media.md`
    margin-bottom: 16px;
  `}
`;

const ExclusiveHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.md`
    gap: 20px;
    justify-content: flex-start;
      `}
`;

const ExclusiveTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const ExclusiveTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #242527;
`;

const ExclusiveQuantity = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #585a60;
`;

const ImageProductWrapper = styled.div<any>`
  position: relative;
  overflow: hidden;
  background: #ffffff;
  border-radius: 4px;
  display: inline-block;
  padding-right: 16px;
`;

const ProductImage = styled(({ noImage, ...rest }) => <LazyImage {...rest} />)<any>`
  width: 70px;
  height: 70px;
  object-fit: ${({ noImage }) => (noImage ? 'contain' : 'cover')};
  ${({ noImage }) => (noImage ? `opacity: 0.3;` : '')};
`;

const NotAllowPurchaseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ProductInfoContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  gap: 4px;
  align-items: center;
  padding-right: 16px;
`;

const ProductTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ProductName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #424242;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  ${media.md`
    font-size: 16px;
    line-height: 22px;
  `}
`;

const ProductStatus = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 4px;
  ${({ color }) => color};
`;

const ExlusiveDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #585a60;
  display: none;

  ${media.md`
    display: block;
  `}
`;

interface ICheckoutStep1 extends InjectedIntlProps {
  notAvailableProducts: CartItem[];
  checkout: ICheckout;
  cart: Cart;
  onGoToDeliveryDetails: () => void;
  onCancelCheckout: () => void;
  onRemoveCartItem: (data: CartItem) => void;
  onRemoveCartAllItem: (data: { supplierId: number }) => void;
  orderDisabled: boolean;
  orderDisabledMessage: string;
}

const CheckoutStep1: FC<ICheckoutStep1> = (props) => {
  const {
    checkout,
    cart,
    onGoToDeliveryDetails,
    onCancelCheckout,
    onRemoveCartItem,
    onRemoveCartAllItem,
    intl,
    orderDisabled,
    notAvailableProducts,
  } = props;

  const lang = localStorage.getItem('lang') || 'vi';

  const onRemoveAllItem = (singleSupplierCart: SingleSupplierCart) => {
    const cancelOrderMessage = intl.formatMessage(messages.removeAllOrdersMessage);
    const yes = intl.formatMessage(utilsMessages.yes);
    const no = intl.formatMessage(utilsMessages.no);
    Modal.confirm({
      title: cancelOrderMessage,
      okText: yes,
      cancelText: no,
      onOk: () => {
        if (singleSupplierCart?.supplier) {
          onRemoveCartAllItem({ supplierId: singleSupplierCart.supplier.id });
        }
      },
    });
  };

  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isMobileSize = width < 575;
        return (
          <>
            {notAvailableProducts && notAvailableProducts.length > 0 && (
              <ExclusiveContainer>
                <ExclusiveHeaderContainer>
                  <ExclusiveTitleContainer>
                    <SVGIcon src={warningIcon} />
                    <ExclusiveTitle>
                      <FormattedMessage {...messages.exclusiveProduct} />
                    </ExclusiveTitle>
                  </ExclusiveTitleContainer>
                  <ExclusiveQuantity>
                    {notAvailableProducts.length}&nbsp;
                    {notAvailableProducts.length > 1 ? (
                      <FormattedMessage {...messages.products} />
                    ) : (
                      <FormattedMessage {...messages.product} />
                    )}
                  </ExclusiveQuantity>
                </ExclusiveHeaderContainer>
                {notAvailableProducts.map((cartItem, index) => (
                  <NotAllowPurchaseItem key={`notAllowPurchaseItem-${index}`} cartItem={cartItem} lang={lang} />
                ))}
              </ExclusiveContainer>
            )}
            <StepContainer>
              {cart.cartSet.map(
                (singleSupplierCart, index) =>
                  singleSupplierCart.supplier && (
                    <CartSetItem
                      key={`singleSupplierCartStep1-${index}`}
                      index={index}
                      singleSupplierCart={singleSupplierCart}
                      isMobileSize={isMobileSize}
                      onRemoveAllItem={onRemoveAllItem}
                      checkout={checkout}
                      onRemoveCartItem={onRemoveCartItem}
                    />
                  ),
              )}
              <FooterWrapper>
                <Footer>
                  <CartTotal>
                    <Value>
                      <FormattedMessage {...utilsMessages.cartTotal} />
                    </Value>
                    <div style={{ flex: 1 }} />
                    <Value>
                      <Money>{checkout.total}</Money>
                    </Value>
                  </CartTotal>
                  <ButtonWrapper>
                    <Button onClick={onCancelCheckout} size="large">
                      <FormattedMessage {...utilsMessages.cancel} />
                    </Button>
                    <div style={{ width: '6pt' }} />
                    <Button
                      type="primary"
                      disabled={orderDisabled}
                      size="large"
                      onClick={() => {
                        onGoToDeliveryDetails();
                      }}
                    >
                      <FormattedMessage {...utilsMessages.next} />
                    </Button>
                  </ButtonWrapper>
                </Footer>
              </FooterWrapper>
            </StepContainer>
          </>
        );
      }}
    </Viewport.Consumer>
  );
};

interface CartSetItemProps {
  index: number;
  singleSupplierCart: SingleSupplierCart;
  isMobileSize: boolean;
  onRemoveAllItem: (singleSupplierCart: SingleSupplierCart) => void;
  checkout: ICheckout;
  onRemoveCartItem: (data: CartItem) => void;
}

const CartSetItem: FC<CartSetItemProps> = (props) => {
  const { index, singleSupplierCart, isMobileSize, onRemoveAllItem, checkout, onRemoveCartItem } = props;

  const { supplier } = singleSupplierCart;

  if (!supplier) return null;

  const total = checkout.totalOfSupplier[supplier.id];
  const isLessThanMOQ = useMemo(() => (supplier?.moq ? total < supplier.moq : 0), [supplier?.moq, total]);
  return (
    <ErrorBoundary key={`step-1-${index}`}>
      {isLessThanMOQ && (
        <UnderMOQ>
          <FormattedMessage {...utilsMessages.underMOQError} />
        </UnderMOQ>
      )}
      <StepItem>
        <StepBox>
          <StepRow>
            <StepCol span={2}>
              {!isMobileSize && (
                <Label>
                  <FormattedMessage {...utilsMessages.supplierName} />
                </Label>
              )}
              <SupplierName>
                <SubplierMark
                  className={supplier.private ? 'unregistered' : ''}
                  style={{ width: '16px', marginRight: '3pt' }}
                />{' '}
                {supplier.name}
              </SupplierName>
              {isMobileSize && (
                <Value color={isLessThanMOQ ? '#FE5043' : ''}>
                  <a
                    style={{ color: '#FE5043', whiteSpace: 'nowrap' }}
                    href="javascript:void(0)"
                    onClick={() => onRemoveAllItem(singleSupplierCart)}
                  >
                    <FormattedMessage {...utilsMessages.deleteAllItems} />
                  </a>
                </Value>
              )}
            </StepCol>
            {isMobileSize ? (
              <StepCol>
                <div>
                  <Label>
                    <FormattedMessage {...utilsMessages.moq} />
                  </Label>
                  <Value>{supplier?.moq && <Money>{supplier.moq}</Money>}</Value>
                </div>
                <div>
                  <Label>
                    <FormattedMessage {...utilsMessages.purchased} />
                  </Label>
                  <Value color={isLessThanMOQ ? '#FE5043' : ''}>
                    <Money>{checkout.totalOfSupplier[supplier.id]}</Money>
                  </Value>
                </div>
              </StepCol>
            ) : (
              <>
                <StepCol>
                  <Label>
                    <FormattedMessage {...utilsMessages.moq} />
                  </Label>
                  <Value>{supplier?.moq && <Money>{supplier.moq}</Money>}</Value>
                </StepCol>
                <StepCol>
                  <Label>
                    <FormattedMessage {...utilsMessages.purchased} />
                  </Label>
                  <Value color={isLessThanMOQ ? '#FE5043' : ''}>
                    <Money>{total}</Money>
                  </Value>
                </StepCol>
              </>
            )}
          </StepRow>
        </StepBox>
        <OrderTable
          name={`step-1-${index}`}
          singleSupplierCart={singleSupplierCart}
          productsQuantity={checkout.quantity}
          onRemoveItem={onRemoveCartItem}
          onRemoveAllItem={onRemoveAllItem}
          shouldTrackCartItem={true}
        />
      </StepItem>
    </ErrorBoundary>
  );
};

interface NotAllowPurchaseItemProps {
  cartItem: CartItem;
  lang: string;
}

const NotAllowPurchaseItem: FC<NotAllowPurchaseItemProps> = (props) => {
  const statusStyle = useMemo(() => {
    if (!props.cartItem.product?.allowPurchase) {
      return { text: translations(messages.exclusive), style: { color: '#806E44', background: '#E6D8BB' } };
    }
    if (!props.cartItem.product?.inStock) {
      return { text: translations(messages.outOfStock), style: { color: '#585A60', background: '#EAEAEB' } };
    }
    return { text: translations(messages.exclusive), style: { color: '#806E44', background: '#E6D8BB' } };
  }, [props.cartItem.product]);

  return (
    <NotAllowPurchaseContainer>
      <ProductInfoContainer>
        <ImageProductWrapper>
          <ProductImage
            noImage={!props?.cartItem?.product?.imageUrl}
            src={props?.cartItem?.product?.imageUrl || noImage}
            alt={props?.cartItem?.product?.name}
          />
        </ImageProductWrapper>
        <ProductTitleContainer>
          <ProductName>
            <Link
              to={`/products/${generateProductSlug(
                props.cartItem.product?.name,
                props.cartItem.product?.supplierInternalCode,
                props.lang,
              )}`}
            >
              <Description>{props.cartItem.product?.name}</Description>
            </Link>
          </ProductName>
          <ProductStatus color={statusStyle.style}>{statusStyle.text}</ProductStatus>
        </ProductTitleContainer>
      </ProductInfoContainer>

      {!props.cartItem.product?.allowPurchase && (
        <ExlusiveDescription>
          <FormattedMessage {...messages.exclusiveProductMessage} />
        </ExlusiveDescription>
      )}
    </NotAllowPurchaseContainer>
  );
};

export default injectIntl(CheckoutStep1);
